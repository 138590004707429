<template>
  <div>
    <div :class="classes.wrapper">
      <div :class="[classes.inner, isPM640VariantA ? 'justify-between' : '']">
        <div>
          <div :class="classes.badge">
            Финальная распродажа
          </div>
          <div :class="[classes.title, isPM640VariantA ? 'xl:w-[505px] xl:text-[44px]' : 'xl:w-[465px] xl:text-[64px]']" v-html="title"></div>
        </div>
        <BaseButton
          type="nuxt-link"
          intent="base-primary"
          size="middle"
          :class="[classes.button, stretchedLinkStyles]"
          :url="url"
          @click="clickOnBanner"
        >
          Выбрать смартфон
        </BaseButton>
        <picture :class="[classes.image, isPM640VariantA ? '' : 'xl:ml-auto']">
          <source
            media="(min-width: 1200px)"
            type="image/webp"
            :srcset="`/static/images/sale-main-banner/${imageDesktop.name}.webp`"
            :width="imageDesktop.width"
            :height="imageDesktop.height"
          >
          <source
            media="(min-width: 1200px)"
            type="image/png"
            :srcset="`/static/images/sale-main-banner/${imageDesktop.name}.png`"
            :width="imageDesktop.width"
            :height="imageDesktop.height"
            :alt="imageDesktop.alt"
          >
          <source
            type="image/webp"
            srcset="/static/images/sale-main-banner/iphone11-mob.webp"
            width="186"
            height="258"
          >
          <img
            src="/static/images/sale-main-banner/iphone11-mob.png"
            width="186"
            height="258"
            alt="iPhone 11"
          >
        </picture>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { stretchedLinkStyles } from '@/utils'

const abStore = useAbStore()
const { isPM640VariantA } = storeToRefs(abStore)

const title = computed(() => isPM640VariantA.value
  ? 'Лови <strong class="text-yellow">выгоду<br class="xl:hidden"> на iPhone</strong> прошлых<br class="xl:hidden"> поколений!'
  : 'Лови <strong class="text-yellow"><span class="xl:text-primary-on-color">выгоду</span><br> на iPhone 11!</strong>')

const url = computed(() => abStore.isPM640VariantA ? '/phone-i?c_sale_buyout=1' : '/phone-i/model-i11?c_sale_buyout=1')

const imageDesktop = computed(() => isPM640VariantA.value
  ? {
      name: 'iphones',
      width: 299,
      height: 232,
      alt: 'iPhones',
    }
  : {
      name: 'iphone11-desk',
      width: 230,
      height: 235,
      alt: 'iPhone 11',
    })

const classes = {
  wrapper: cx([
    'relative',
    'bg-gradient-sale',
    'text-center',
    'xl:text-left',
    'text-primary-on-color',
    'aspect-square',
    'xs:aspect-[5/4]',
    'xl:aspect-auto',
    'rounded-3xl',
  ]),
  inner: cx([
    'py-[40px]',
    'px-[32px]',
    'xl:py-0',
    'xl:px-[72px]',
    'flex',
    'flex-col',
    'xl:flex-row',
    'items-center',
    'gap-6',
    'bg-[url("/static/images/sale-main-banner/bg-mob.png")]',
    'xl:bg-[url("/static/images/sale-main-banner/bg-desk.png")]',
    'bg-cover',
    'xl:bg-auto',
    'bg-[center_bottom_-32px]',
    'xl:bg-center',
    'bg-no-repeat',
    'rounded-3xl',
  ]),
  badge: cx([
    'uppercase',
    'font-bold',
    'text-[14px]/[16px]',
    'xl:text-base/[16px]',
    'text-bg-yellow-yellow',
    'mb-1',
  ]),
  title: cx([
    'text-[28px]',
    'leading-[90%]',
    'font-bold',
    'mb-[242px]',
    'xl:mb-0',
  ]),
  button: cx([
    'w-full',
    'xl:max-w-[284px]',
    'py-[16px]',
    '!text-[24px]/[28px]',
    '!font-bold',
    'whitespace-nowrap',
    'z-[1]',
  ]),
  image: cx([
    'absolute',
    'bottom-[81px]',
    'xl:static',
    'xl:bottom-0',
  ]),
}

const clickOnBanner = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.SALE_BANNER_CKICKED)
  emitMixpanelEventSaleBannerClicked()
}
</script>